/* http://www.sitepoint.com/using-helper-classes-dry-scale-css/ */
/* https://github.com/ovdojoey/Juiced/blob/master/sass/_helpers.scss */
$spacer:   1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  none: (
    x: 0,
    y: 0
  ),
  quarter: (
    x: ($spacer-x / 4),
    y: ($spacer-y / 4)
  ),
  half: (
    x: ($spacer-x / 2),
    y: ($spacer-y / 2)
  ),
  one: (
    x: $spacer-x,
    y: $spacer-y
  ),
  two: (
    x: ($spacer-x * 2),
    y: ($spacer-y * 2)
  ),
  four: (
    x: ($spacer-x * 4),
    y: ($spacer-y * 4)
  )
);

.zindex-1 {
  z-index: 1; }

.zindex-2 {
  z-index: 2; }

.zindex-3 {
  z-index: 3; }

.zindex-4 {
  z-index: 4; }

.zindex-5 {
  z-index: 5; }

.zindex-6 {
  z-index: 6; }

.zindex-7 {
  z-index: 7; }

.zindex-8 {
  z-index: 8; }

.zindex-9 {
  z-index: 9; }

.fixed {
  position: fixed; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.top {
  top: 0; }

.left {
  left: 0; }

.bottom {
  bottom: 0; }

.right {
  right: 0; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-just
.text-justify {
  text-align: justify; }

.align-top {
  vertical-align: top; }

.align-bottom {
  vertical-align: bottom; }

.align-middle {
  vertical-align: middle; }

.align-baseline {
  vertical-align: baseline; }

.vertical-center
{
    top: 50%;
    transform: translateY(-50%);    
} 

.fit {
  max-width: 100%; }

.half-width {
  width: 50%; }

.full-width {
  width: 100%; }

.full-height {
  height: 100%; }

/* vieport height */
.viewport-quarter-height
{
    height: 25vh;
}

.viewport-onethird-height
{
    height: calc(100vh/3);
}

.viewport-half-height
{
    height: 50vh;
}

.viewport-twothirds-height
{
    height: calc(100vh/3*2);
}

.viewport-threequarters-height,
.viewport-threefourths-height,
{
    height: 75vh;
}

.viewport-full-height
{
    height: 100vh;
}

.inline {
  display: inline; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.hidden {
  display: none; }

.overflow-hidden
{
  overflow: hidden;
}

.overflow-visible
{
  overflow: visible;
}

.white {
  color: #fff; }

.white-bg {
  background-color: #fff; }

.bold {
  font-weight: bold; }

.regular {
  font-weight: normal; }

.italic {
  font-style: italic; }

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.break-word {
  word-wrap: break-word; }

.no-wrap {
  white-space: nowrap !important; }

.no-bullets, .list-bare {
  list-style: none; }

.bullets-inside {
  list-style: inside; }

.list-bare {
  margin: 0;
  padding: 0; }

.margin-auto {
  margin: auto; }

@each $prop, $abbrev in (margin: margin, padding: padding) {
  @each $size, $lengths in $spacers {
    $length-x:   map-get($lengths, x);
    $length-y:   map-get($lengths, y);

    .#{$abbrev}-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
    .#{$abbrev}-top-#{$size} { #{$prop}-top:    $length-y !important; }
    .#{$abbrev}-right-#{$size} { #{$prop}-right:  $length-x !important; }
    .#{$abbrev}-bottom-#{$size} { #{$prop}-bottom: $length-y !important; }
    .#{$abbrev}-left-#{$size} { #{$prop}-left:   $length-x !important; }

    // Axes
    .#{$abbrev}-x-#{$size} {
      #{$prop}-right:  $length-x !important;
      #{$prop}-left:   $length-x !important;
    }
    .#{$abbrev}-y-#{$size} {
      #{$prop}-top:    $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }
  }
}

/* http://codeitdown.com/css-square-rectangle/ */
.restangle-box-quarter-height
{
    overflow: hidden;
}

.restangle-box-quarter-height:before
{
    content: "";
    display: block;
    padding-top: 25%;
}

.restangle-box-onethird-height
{
    overflow: hidden;
}

.restangle-box-onethird-height:before
{
    content: "";
    display: block;
    padding-top: calc(100%/3);
}

.restangle-box-half-height
{
    overflow: hidden;
}

.restangle-box-half-height:before
{
    content: "";
    display: block;
    padding-top: 50%;
}

.restangle-box-twothirds-height
{
    overflow: hidden;
}

.restangle-box-twothirds-height:before
{
    content: "";
    display: block;
    padding-top: calc(100%/3*2);
}

.squared,
.square-box,
.restangle-box-one-height
{
    overflow: hidden;
}

.squared:before,
.square-box:before,
.restangle-box-one-height:before
{
    content: "";
    display: block;
    padding-top: 100%;
}

.restangle-box-two-height
{
    overflow: hidden;
}

.restangle-box-two-height:before
{
    content: "";
    display: block;
    padding-top: 200%;
}

.restangle-box-three-height
{
    overflow: hidden;
}

.restangle-box-three-height:before
{
    content: "";
    display: block;
    padding-top: 300%;
}

.restangle-box-four-height
{
    overflow: hidden;
}

.restangle-box-four-height:before
{
    content: "";
    display: block;
    padding-top: 400%;
}

.background-fill,
.background-cover {
    background-size: cover;
}

.background-repeat {
    background-repeat: repeat;
}

.background-no-repeat {
    background-repeat: no-repeat;
}

.background-left-top,
.background-top-left
{
    background-position: left top;
}

.background-top,
.background-top-center,
.background-center-top
{
    background-position: top center;
}

.background-right-top,
.background-top-right
{
    background-position: right top;
}

.background-left,
.background-left-center,
.background-center-left
{
    background-position: left center;  
}

.background-center,
.background-center-center
{
    background-position: center center;
}

.background-right,
.background-right-center,
.background-center-right
{
    background-position: right center;  
}

.background-left-bottom,
.background-bottom-left
{
    background-position: left bottom;
}

.background-bottom,
.background-center-bottom,
.background-bottom-center
{
    background-position: bottom center;
}

.background-right-bottom,
.background-bottom-right
{
    background-position: right bottom;
}

.background-fixed {
    background-attachment: fixed;
}

.background-scroll {
    background-attachment: scroll;
}

.font-normal {
    font-weight: normal !important;
}
.font-bold {
    font-weight: bold !important;
}
.font-bolder {
    font-weight: bolder !important;
}
.font-lighter {
    font-weight: lighter !important;
}
.font-light {
    font-weight: normal !important;
}
.font-weight-100 {
    font-weight: 100 !important;
}
.font-weight-200 {
    font-weight: 200 !important;
}
.font-weight-300 {
    font-weight: 300 !important;
}
.font-weight-400 {
    font-weight: 400 !important;
}
.font-weight-500 {
    font-weight: 500 !important;
}
.font-weight-600 {
    font-weight: 600 !important;
}
.font-weight-700 {
    font-weight: 700 !important;
}
.font-weight-800 {
    font-weight: 800 !important;
}
.font-weight-900 {
    font-weight: 900 !important;
}

.opacity-0 {
    opacity: 0;
}

.opacity-25 {
    opacity: .25;
}

.opacity-50 {
    opacity: .5;
}

.opacity-75 {
    opacity: .75;
}

.opacity-100 {
    opacity: 1;
}

.scale-0 {
    transform: scale(0);
}

.scale-50 {
    transform: scale(0.5);
}

.scale-100 {
    transform: scale(1);
}

.scale-125 {
    transform: scale(1.25);
}

.hover-opacity-0 {
  &:hover {
    opacity: 0;
  }
}
.hover-opacity-25 {
  &:hover{
    opacity: .25;
  }
}
.hover-opacity-50 {
  &:hover{
    opacity: .5;
  }
}
.hover-opacity-75 {
  &:hover{
    opacity: .75;
  }
}
.hover-opacity-100 {
  &:hover{
    opacity: 1;
  }
}

.hover-scale-0 {
  &:hover{
    transform: scale(0);
  }
}
.hover-scale-50 {
  &:hover{
    transform: scale(0.5);
  }
}
.hover-scale-100{
  &:hover{
    transform: scale(1);
  }
}
.hover-scale-105{
  &:hover{
    transform: scale(1.05);
  }
}

.transition-background{
  transition-property: background;
}
.transition-color{
  transition-property: color;
}
.transition-opacity {
  transition-property: opacity;
}
.transition-transform{
  transition-property: transform;
}
.transition-all{
  transition-property: all;
}

// mixed transitions
.transition-background-color{
  transition-property: background, color;
}
.transition-background-color-opacity{
  transition-property: background, color, opacity;
}
.transition-background-color-opacity-transform{
  transition-property: background, color, opacity, transform;
}
.transition-opacity-transform{
  transition-property: opacity, transform;
}

.transition-linear{
  transition-timing-function: linear;
}
.transition-ease{
  transition-timing-function: ease;
}
.transition-ease-in {
  transition-timing-function: ease-in;
}
.transition-ease-out {
  transition-timing-function: ease-out;
}
.transition-ease-in-out {
  transition-timing-function: ease-in-out;
}


.transition, .transition-normal {
  transition-duration: .5s;
}
.transition-fast {
  transition-duration: .3s;
}
.transition-slow {
  transition-duration: .8s;
}
.transition-slow-2x {
  transition-duration: 1.2s;
}
.transition-slow-4x {
  transition-duration: 2s;
}

// Cursors
.cursor-pointer{
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.cursor-auto {
  cursor: auto;
}
.cursor-none {
  cursor: none;
}
.cursor-not-allowed{
  cursor: not-allowed;
}
